<button class="an-close-icon-wrap" aria-label="Close" [ngClass]="{'an-icon-32': size == 32}" (click)="onClick.emit()">

  <ng-container *ngIf="size == 24; else icon32">
    <svg class="an-close-icon" width="12" height="12" viewBox="0 0 1024 1024" version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M810.65984 170.65984q18.3296 0 30.49472 12.16512t12.16512 30.49472q0 18.00192-12.32896 30.33088l-268.67712 268.32896 268.67712 268.32896q12.32896 12.32896 12.32896 30.33088 0 18.3296-12.16512 30.49472t-30.49472 12.16512q-18.00192 0-30.33088-12.32896l-268.32896-268.67712-268.32896 268.67712q-12.32896 12.32896-30.33088 12.32896-18.3296 0-30.49472-12.16512t-12.16512-30.49472q0-18.00192 12.32896-30.33088l268.67712-268.32896-268.67712-268.32896q-12.32896-12.32896-12.32896-30.33088 0-18.3296 12.16512-30.49472t30.49472-12.16512q18.00192 0 30.33088 12.32896l268.32896 268.67712 268.32896-268.67712q12.32896-12.32896 30.33088-12.32896z" />
    </svg>
  </ng-container>

  <ng-template #icon32>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M16 32.751C7.16344 32.751 0 25.5875 0 16.751C0 7.91442 7.16344 0.750977 16 0.750977C24.8366 0.750977 32 7.91442 32 16.751C32 25.5875 24.8366 32.751 16 32.751Z"
        fill="#EAEAEA" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M21.6302 20.5789C22.1281 21.0771 22.1281 21.8819 21.6302 22.38C21.3819 22.6284 21.0559 22.7531 20.7296 22.7531C20.4036 22.7531 20.0775 22.6284 19.8292 22.38L16.0021 18.5526L12.1749 22.38C11.9266 22.6284 11.6005 22.7531 11.2745 22.7531C10.9483 22.7531 10.6223 22.6284 10.3739 22.38C9.87602 21.8819 9.87602 21.0771 10.3739 20.5789L14.2012 16.7519L10.3739 12.9247C9.87602 12.4266 9.87602 11.6218 10.3739 11.1237C10.872 10.6258 11.6768 10.6258 12.1749 11.1237L16.0021 14.951L19.8292 11.1237C20.3273 10.6258 21.1321 10.6258 21.6302 11.1237C22.1281 11.6218 22.1281 12.4266 21.6302 12.9247L17.8029 16.7519L21.6302 20.5789Z"
        fill="#BCBCBC" />
    </svg>
  </ng-template>
</button>