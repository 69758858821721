<div class="an-toast" role="alert" aria-live="assertive" aria-atomic="true" [@flyInOut]="transitionState"
  [ngStyle]="{'background-color': color}" [ngClass]="{
    'an-toast--padding': !showActionText && actionText,
    'an-toast-container-v3': isVersion3
  }" *ngIf="showToast">

  <div class="an-toast__content" [ngClass]="{
    'an-toast__content-max-width': showActionText,
    'an-toast-v3-reverse': isVersion3Reversed,
    'an-toast__content-v3': isVersion3 && !isVersion3Reversed
  }">

    <div class="an-toast__icon" [ngClass]="{
      'an-toast__icon-v3-reverse': isVersion3Reversed
    }">
      <div class="an-icon" [ngClass]="{
        'an-icon-v3-reverse': isVersion3Reversed,
        'an-icon-v3': isVersion3 && !isVersion3Reversed
      }">

        <svg class="an-icon__check" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 15 13"
          *ngIf="actionText && !isVersion3">
          <path id="Path_272983" data-name="Path 272983"
            d="M110.635,77.065a1.46,1.46,0,0,1,0-2.09,1.523,1.523,0,0,1,2.127,0l2.885,2.835,6.86-8.719a1.518,1.518,0,0,1,2.1-.266,1.457,1.457,0,0,1,.271,2.067l-7.834,9.957a1.515,1.515,0,0,1-2.322.235l-4.09-4.019Z"
            transform="translate(-110.194 -68.517)" [ngStyle]="{ fill: color }"></path>
        </svg>

        <svg class="an-icon__cross" width="19" height="19" viewBox="0 0 1024 1024" version="1.1"
          (click)="showToast=false" xmlns="http://www.w3.org/2000/svg" *ngIf="!actionText || isVersion3Reversed">
          <path
            d="M810.65984 170.65984q18.3296 0 30.49472 12.16512t12.16512 30.49472q0 18.00192-12.32896 30.33088l-268.67712 268.32896 268.67712 268.32896q12.32896 12.32896 12.32896 30.33088 0 18.3296-12.16512 30.49472t-30.49472 12.16512q-18.00192 0-30.33088-12.32896l-268.32896-268.67712-268.32896 268.67712q-12.32896 12.32896-30.33088 12.32896-18.3296 0-30.49472-12.16512t-12.16512-30.49472q0-18.00192 12.32896-30.33088l268.67712-268.32896-268.67712-268.32896q-12.32896-12.32896-12.32896-30.33088 0-18.3296 12.16512-30.49472t30.49472-12.16512q18.00192 0 30.33088 12.32896l268.32896 268.67712 268.32896-268.67712q12.32896-12.32896 30.33088-12.32896z"
            [ngStyle]="{ fill: isVersion3 ? '#FFFFFF' : color }" />
        </svg>

        <svg class="an-icon__check-v3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" 
        *ngIf="(actionText && actionText == 'SaveSearchV3') && isVersion3 && !isVersion3Reversed">
          <path d="M20 6L9 17L4 12" stroke="#45B167" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        <svg class="an-icon__check-v3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" 
        *ngIf="(actionText && actionText == 'DeleteSearchV3') && isVersion3 && !isVersion3Reversed">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2 5.75C2 5.33579 2.33579 5 2.75 5H20.75C21.1642 5 21.5 5.33579 21.5 5.75C21.5 6.16421 21.1642 6.5 20.75 6.5H2.75C2.33579 6.5 2 6.16421 2 5.75Z"
            fill="#EAEAEA" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M9.75 2.5C9.41848 2.5 9.10054 2.6317 8.86612 2.86612C8.6317 3.10054 8.5 3.41848 8.5 3.75V5H15V3.75C15 3.41848 14.8683 3.10054 14.6339 2.86612C14.3995 2.6317 14.0815 2.5 13.75 2.5H9.75ZM16.5 5V3.75C16.5 3.02065 16.2103 2.32118 15.6945 1.80546C15.1788 1.28973 14.4793 1 13.75 1H9.75C9.02065 1 8.32118 1.28973 7.80546 1.80546C7.28973 2.32118 7 3.02065 7 3.75V5H4.75C4.33579 5 4 5.33579 4 5.75V19.75C4 20.4793 4.28973 21.1788 4.80546 21.6945C5.32118 22.2103 6.02065 22.5 6.75 22.5H16.75C17.4793 22.5 18.1788 22.2103 18.6945 21.6945C19.2103 21.1788 19.5 20.4793 19.5 19.75V5.75C19.5 5.33579 19.1642 5 18.75 5H16.5ZM5.5 6.5V19.75C5.5 20.0815 5.6317 20.3995 5.86612 20.6339C6.10054 20.8683 6.41848 21 6.75 21H16.75C17.0815 21 17.3995 20.8683 17.6339 20.6339C17.8683 20.3995 18 20.0815 18 19.75V6.5H5.5Z"
            fill="#EAEAEA" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M13.75 10C14.1642 10 14.5 10.3358 14.5 10.75V16.75C14.5 17.1642 14.1642 17.5 13.75 17.5C13.3358 17.5 13 17.1642 13 16.75V10.75C13 10.3358 13.3358 10 13.75 10Z"
            fill="#EAEAEA" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M9.75 10C10.1642 10 10.5 10.3358 10.5 10.75V16.75C10.5 17.1642 10.1642 17.5 9.75 17.5C9.33579 17.5 9 17.1642 9 16.75V10.75C9 10.3358 9.33579 10 9.75 10Z"
            fill="#EAEAEA" />
        </svg>

      </div>
    </div>

    <div class="an-content">
      <div class="an-content__title" [ngClass]="{
        'an-content__title-v3': isVersion3
      }"> {{ title }} </div>
      <div class="an-content__message" *ngIf="message" [ngClass]="{
        'an-content__message-v3': isVersion3
      }"> {{ message }} </div>
    </div>

  </div>

  <ng-container *ngIf="showActionText">
    <div class="an-toast__action" [attr.aria-label]="actionText" *ngIf="actionText" (click)="actionClick($event)">
      <span class="an-action__text"> {{ actionText }} </span>
    </div>
  </ng-container>

</div>