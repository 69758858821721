import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Output, ViewEncapsulation, Input, OnInit, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';


@Component({
  selector: 'an-toast',
  templateUrl: './an-toast.component.html',
  styleUrls: ['./an-toast.component.scss'],
  animations: [
    trigger('flyInOut', [
      transition('void => bottom', [
        style({ transform: 'translateY(100%)' }),
        animate('200ms', style({ transform: 'translateY(0)' }))
      ]),
      transition('bottom => void', [
        style({ transform: 'translateY(0)' }),
        animate('200ms', style({ transform: 'translateY(100%)' }))
      ]),
      transition('void => left', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms', style({ transform: 'translateX(0)' }))
      ]),
      transition('left => void', [
        style({ transform: 'translateX(0)' }),
        animate('200ms', style({ transform: 'translateX(-100%)' }))
      ]),
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class AnToastComponent implements OnInit, OnDestroy {

  subs = new Subscription();
  transitionState!: string;
  mobileQuery!: MediaQueryList;
  showToast = true;

  @Input() color!: string | undefined;
  @Input() title!: string | undefined;
  @Input() message!: string | undefined;
  @Input() actionText!: string | undefined;
  @Input() showActionText!: boolean;
  @Input() isVersion3!: boolean;
  @Input() isVersion3Reversed!: boolean;

  @Output() onActionClick = new EventEmitter<Event>();

  ngOnInit(): void {
    this.mobileQuery = window.matchMedia("(max-width: 768px)");
    this.transitionState = this.mobileQuery.matches ? 'bottom' : 'left';
    this.subs.add(fromEvent<MediaQueryListEvent>(this.mobileQuery, 'change').subscribe(e => e.matches ? 'bottom' : 'left'));
  }

  actionClick(event: Event): void {
    this.onActionClick.emit(event);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
