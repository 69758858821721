import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({ selector: '[trapFocus]' })
export class TrapFocusDirective {
  // all the elements inside  to make focusable
  focusableElements =
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

  firstFocusableElement!: HTMLElement;
  lastFocusableElement!: HTMLElement;
  focusableContent!: NodeListOf<HTMLElement>;

  constructor(private elRef: ElementRef<HTMLElement>) {}

  @HostListener('document:keydown', ['$event']) onKeydownHandler(
    e: KeyboardEvent
  ) {
    let isTabPressed = e.key === 'Tab';
    const hostEl = this.elRef.nativeElement;
    if (!this.firstFocusableElement) {
      this.firstFocusableElement = hostEl.querySelectorAll<HTMLElement>(
        this.focusableElements
      )[0]; // get first element to be focused inside host element
    }


    this.focusableContent = hostEl.querySelectorAll<HTMLElement>(
      this.focusableElements
    );
  

    if (!this.lastFocusableElement) {
      this.lastFocusableElement =
        this.focusableContent[this.focusableContent.length - 1]; // get last element to be focused inside host element
    }
    const found = Array.from(this.focusableContent).find((node) =>
      node.isEqualNode(document.activeElement)
    );
    // Check if the active focus is inside host element
    if (!found) {
      this.firstFocusableElement.focus();
    }

    if (!isTabPressed) {
      return;
    }

    if (e.shiftKey) {
      // if shift key pressed for shift + tab combination
      if (document.activeElement === this.firstFocusableElement) {
        this.lastFocusableElement.focus(); // add focus for the last focusable element
        e.preventDefault();
      }
    } else {
      // if tab key is pressed
      if (document.activeElement === this.lastFocusableElement) {
        // if focused has reached to last focusable element then focus first focusable element after pressing tab
        this.firstFocusableElement.focus(); // add focus for the first focusable element
        e.preventDefault();
      }
    }
  }
}
