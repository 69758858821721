import { ComponentFactoryResolver, Injectable, Injector, Inject, ApplicationRef, ComponentRef, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AnToastComponent } from './an-toast.component';
import { DOCUMENT } from '@angular/common';


interface AnToastConfig {
  color: string;
  title: string;
  message?: string;
  actionText?: string;
  showActionText: boolean;
  isVersion3?: boolean;
  isVersion3Reversed?: boolean;
}

interface AnToastElement {
  selector: HTMLElement;
  ref: ComponentRef<AnToastComponent>;
}

@Injectable({
  providedIn: 'root'
})
export class AnToastService {
  private renderer!: Renderer2;

  constructor(
    private injector: Injector,
    private applicationRef: ApplicationRef,
    @Inject(DOCUMENT) private document: Document,
    private componentFactoryResolver: ComponentFactoryResolver,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.createToastContainer();
  }


  save(config?: { color?: string, title?: string, actionText?: string, message?: string, showActionText?: boolean }): Observable<Event> {
    let { color, title, actionText, message, showActionText } = { ...config };

    const element = this.createToastElement();

    actionText = actionText || 'View All';

    if (window.location.href.indexOf("/my-account/my-favorite") > -1) {
      actionText = ' ';
    }

    this.setToastValues(element.ref, {
      color: color || '#0F5485',
      title: title || 'Saved to Favorites',
      actionText: actionText,
      message,
      showActionText: showActionText == false ? false : true
    });

    this.openToast(element.selector);
    this.closeToast(element, 5000, 5500);

    return this.handleReturn(element);
  }

  alreadyExists(config?: { color?: string, title?: string, actionText?: string, message?: string, showActionText?: boolean }): Observable<Event> {
    let { color, title, actionText, message, showActionText } = { ...config };

    const element = this.createToastElement();

    actionText = actionText || 'View All';

    if (window.location.href.indexOf("/my-account/my-favorite") > -1) {
      actionText = ' ';
    }

    this.setToastValues(element.ref, {
      color: color || '#0F5485',
      title: title || 'This Vehicle Is Already in Your Favorites',
      actionText: actionText,
      message,
      showActionText: showActionText == false ? false : true
    });

    this.openToast(element.selector);
    this.closeToast(element, 5000, 5500);

    return this.handleReturn(element);
  }

  remove(config?: { color?: string, title?: string, actionText?: string }): Observable<Event> {
    const { color, title, actionText } = { ...config };

    const element = this.createToastElement();

    this.setToastValues(element.ref, {
      color: color || '#555555',
      title: title || 'Removed from Favorites',
      actionText: actionText || 'Undo',
      showActionText: true
    });

    this.openToast(element.selector);
    this.closeToast(element, 5000, 5500);

    return this.handleReturn(element);
  }

  error(config?: { color?: string, title?: string }): void {
    const { color, title } = { ...config };

    const element = this.createToastElement();

    this.setToastValues(element.ref, {
      color: color || '#D0021B',
      title: title || 'Error Saving to Favorites',
      showActionText: false
    });

    this.openToast(element.selector);
    this.closeToast(element, 5000, 5500);
  }

  saveSearchErrorV3(config?: { color?: string, title?: string, message?: string, isVersion3?: boolean, isVersion3Reversed?: boolean }): void {
    const { color, title, message, isVersion3, isVersion3Reversed } = { ...config };

    const element = this.createToastElement();

    this.setToastValues(element.ref, {
      color: color || '#000000',
      title: title || 'We Hit a Speed Bump!',
      message: message || 'There was an issue saving your search, please try again.',
      showActionText: false,
      isVersion3,
      isVersion3Reversed
    });

    this.openToast(element.selector);
    this.closeToast(element, 5000, 5500);
  }

  saveSearchV3(config?: { color?: string, title?: string, actionText?: string, message?: string, showActionText?: boolean, isVersion3?: boolean, isVersion3Reversed?: boolean }): Observable<Event> {
    let { color, title, actionText, message, showActionText, isVersion3, isVersion3Reversed } = { ...config };

    const element = this.createToastElement();

    actionText = 'SaveSearchV3';

    if (window.location.href.indexOf("/my-account/my-favorite") > -1) {
      actionText = ' ';
    }

    this.setToastValues(element.ref, {
      color: color || '#000000',
      title: title || 'Search Saved',
      actionText: actionText,
      message,
      showActionText: showActionText == false ? false : true,
      isVersion3,
      isVersion3Reversed
    });

    this.openToast(element.selector);
    this.closeToast(element, 5000, 5500);

    return this.handleReturn(element);
  }

  deleteSearchV3(config?: { color?: string, title?: string, actionText?: string, message?: string, showActionText?: boolean, isVersion3?: boolean, isVersion3Reversed?: boolean }): Observable<Event> {
    let { color, title, actionText, message, showActionText, isVersion3, isVersion3Reversed } = { ...config };

    const element = this.createToastElement();

    actionText = 'DeleteSearchV3';

    if (window.location.href.indexOf("/my-account/my-favorite") > -1) {
      actionText = ' ';
    }

    this.setToastValues(element.ref, {
      color: color || '#000000',
      title: title || 'Your search is deleted',
      actionText: actionText,
      message,
      showActionText: showActionText == false ? false : true,
      isVersion3,
      isVersion3Reversed
    });

    this.openToast(element.selector);
    this.closeToast(element, 5000, 5500);

    return this.handleReturn(element);
  }

  limit(config?: { color?: string, title?: string, message?: string }) {
    const { color, title, message } = { ...config };

    const element = this.createToastElement();

    this.setToastValues(element.ref, {
      color: color || '#555555',
      title: title || 'Favorites Limit Reached',
      message: message || 'Remove some favorites to add more',
      showActionText: false
    });

    this.openToast(element.selector);
    this.closeToast(element, 5000, 5500);
  }

  private setToastValues(ref: ComponentRef<AnToastComponent>, config: AnToastConfig) {
    ref.instance.color = config.color;
    ref.instance.title = config.title;
    ref.instance.message = config.message;
    ref.instance.actionText = config.actionText;
    ref.instance.showActionText = config.showActionText;
    ref.instance.isVersion3 = config.isVersion3 || false;
    ref.instance.isVersion3Reversed = config.isVersion3Reversed || false;
  }

  private createToastContainer() {
    const divOverlayContainer = this.renderer.createElement('div');
    this.renderer.setAttribute(divOverlayContainer, 'aria-live', 'polite');
    this.renderer.addClass(divOverlayContainer, 'an-overlay-container');

    const divToastContainer = this.renderer.createElement('div');
    this.renderer.setAttribute(divToastContainer, 'id', 'an-toast-container-id');
    this.renderer.addClass(divToastContainer, 'an-toast-postion');
    this.renderer.addClass(divToastContainer, 'an-toast-container');
    this.renderer.appendChild(divOverlayContainer, divToastContainer);

    this.renderer.appendChild(this.document.body, divOverlayContainer);
  }

  private createToastElement(): AnToastElement {
    const selector = this.document.createElement('an-toast-component');
    const factory = this.componentFactoryResolver.resolveComponentFactory(AnToastComponent);
    const ref = factory.create(this.injector, [], selector);
    this.applicationRef.attachView(ref.hostView);
    return { selector, ref }
  }

  private getToastContainer(): HTMLElement | null {
    return this.document.body.querySelector('#an-toast-container-id');
  }

  private openToast(selector: HTMLElement) {
    const container = this.getToastContainer();

    container
      ? this.renderer.appendChild(container, selector)
      : console.error('"an-toast-container-id" does not exist');
  }

  private closeToast(element: AnToastElement, closeTimeout: number, removeTimeout: number): void {
    const container = this.getToastContainer();
    if (container) {
      timer(closeTimeout).pipe(take(1)).subscribe(_ => element.ref.instance.showToast = false);
      timer(removeTimeout).pipe(take(1)).subscribe(_ => this.renderer.removeChild(container, element.selector));
    } else {
      console.error('"an-toast-container-id" does not exist');
    }
  }

  private handleReturn(element: AnToastElement) {
    return element.ref.instance.onActionClick.asObservable().pipe(tap(_ => {
      this.closeToast(element, 0, 500);
    }));
  }

}
