import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NotificationModalLibComponent } from './notification-modal.component';
import { SafeUrlPipeModal } from './pipes/safe-url.pipe';
import { AnModalModule } from '@autonation/dt-an-components-lib/an-modal';
import { NotificationModalHostComponent } from './notification-modal-host/notification-modal-host.component';

@NgModule({
  declarations: [
    NotificationModalLibComponent,
    SafeUrlPipeModal,
    NotificationModalHostComponent
  ],
  imports: [
    CommonModule,
    AnModalModule
    
  ],
  exports: [
    NotificationModalHostComponent
  ],
  providers: [DatePipe]
})
export class NotificationModule { 
  constructor() {
  }
}
