import { Component, OnInit, ComponentRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AnModalService } from '@autonation/dt-an-components-lib/an-modal';
import { AnToastService } from 'projects/dt-an-components-lib/an-toast/src/an-toast.service';
import { AnAuthenticationModalService } from 'projects/dt-an-components-lib/authentication-modal/public-api';
import { Subject, timer } from 'rxjs';
import { IframeComponent } from '../../iframe/iframe.component';
import { HoursComponent } from '../../hours/hours.component';
import { AnVerifyEmailModalService } from '@autonation/dt-an-components-lib/verify-email-modal/public-api';
import { AnAddVehicleService } from '@autonation/dt-an-components-lib/an-add-vehicle-modal/src/add-vehicle.service';
import { AnCompareVehicleService } from '@autonation/dt-an-components-lib/an-compare-vehicle-modal';
import { mock } from '@autonation/dt-an-components-lib/an-compare-vehicle-modal/src/compare-vehicle.mock';



@Component({
  selector: 'app-index',
  templateUrl: './app-index.component.html',
  styleUrls: ['./app-index.component.scss']
})
export class AppIndexComponent implements OnInit {

  toastGroup!: FormGroup;
  test = '11,235';
  test2 = '11235';

  constructor(
    private toastService: AnToastService,
    private authModalService: AnAuthenticationModalService,
    private verifyEmailModalService: AnVerifyEmailModalService,
    private addVehicleModalService: AnAddVehicleService,
    private compareVehicleModalService: AnCompareVehicleService,
    private fb: FormBuilder,
    private modalService: AnModalService
  ) {
    this.toastGroup = this.fb.group({
      color: ['green'],
      title: ['Custom text'],
      actionText: ['Click me']
    });
  }

  openSave() {
    this.toastService.save().subscribe((e: any) => {
      console.log('action clicked and close toast', e);
    });
  }

  openRemove() {
    this.toastService.remove({

    }).subscribe(e => {
      console.log('action clicked and close toast', e);
    });
  }

  openError() {
    this.toastService.error();
  }

  openLimit() {
    this.toastService.limit();
  }

  openExists() {
    this.toastService.alreadyExists().subscribe((e: any) => {
      console.log('action clicked and close toast', e);
    });
  }

  openSaveSearchSaveToast() {
    let config = {
      title: 'Search Saved',
      showActionText: false
    }
    this.toastService.save(config).subscribe((e: any) => {
      console.log('action clicked and close toast', e);
    });
  }

  openSaveSearchSaveV3Toast() {
    let config = {
      title: 'Search Saved',
      showActionText: false,
      isVersion3: true,
      isVersion3Reversed: false
    }
    this.toastService.saveSearchV3(config).subscribe((e: any) => {
      console.log('action clicked and close toast', e);
    });
  }

  openSaveSearchDeleteToast() {
    let config = {
      color: '#333',
      title: 'Search Deleted',
      showActionText: false
    }
    this.toastService.save(config).subscribe((e: any) => {
      console.log('action clicked and close toast', e);
    });
  }

  openSaveSearchDeleteToastV3() {
    let config = {
      title: 'Your search is deleted',
      showActionText: false,
      isVersion3: true,
      isVersion3Reversed: false
    }
    this.toastService.deleteSearchV3(config).subscribe((e: any) => {
      console.log('action clicked and close toast', e);
    });
  }

  openSaveSearchErrorToast() {
    let config = {
      title: 'Error Saving Search',
      showActionText: false
    }
    this.toastService.error(config);
  }

  openSaveSearchErrorV3Toast() {
    let config = {
      title: 'We Hit a Speed Bump!',
      message: 'There was an issue saving your search, please try again.',
      showActionText: false,
      isVersion3: true,
      isVersion3Reversed: true
    }
    this.toastService.saveSearchErrorV3(config);
  }

  openCustom() {
    this.toastService.save({
      title: 'Success',
      message: 'Your appointment was cancelled.',
      showActionText: false
    });
  }

  heartIconOn = false;

  clickHeartIcon(state: boolean) {
    console.log(state);

    this.heartIconOn = !state;
  }

  openAuthModal() {
    this.authModalService.triggerAuthModal().subscribe((doAuthOnClosing: any) => {
      if (doAuthOnClosing) {
        console.log('Navigate to Authentication Page with', doAuthOnClosing, 'mode');
        try {
          if ((window as any).auth) {
            const extraParam: string = `mg=f`;
            (window as any).auth.loginWithAuth0(doAuthOnClosing, extraParam);
          }
        } catch (e) {/* istanbul ignore next */
          console.log(e);
        }
      }
    });
  }

  getFavorites(): any {
    return {
      "Data": {
        "favoriteVehicles": [
          {
            "vin": "3FMCR9B69NRE15880",
            "vehicleStatus": "in_stock"
          },
          {
            "vin": "1J4NF4GB9AD675093",
            "vehicleStatus": "in_stock"
          }
        ],
        "favoriteVehiclesCount": 2,
        "maxFavoriteVehiclesLimit": 4
      },
      "Status": true,
      "HttpStatusCode": 200,
      "ErrorMassage": "",
      "Exception": null
    };
  }

  ngOnInit(): void {
    this.favorites = this.getFavorites();


    // timer(3000).subscribe(v => {
    //   this.vehicles.push({Vin: '1FATP8FF3N5146881', vehicleSaved: false});
    // });
  }

  favorites: any;

  loading = false;

  vehicles = [
    { vehicleSaved: false, Vin: '1G1ZC5E04CF115960' },
    { vehicleSaved: false, Vin: '3FMCR9B69NRE20853' },
    { vehicleSaved: false, Vin: '1FMDE5BH6NLB77883' },
    { vehicleSaved: false, Vin: '2FMPK4G96NBA96254' },
    { vehicleSaved: true, Vin: '3FMCR9B69NRE15880' },
    { vehicleSaved: true, Vin: 'W1KWJ6EB7NG115039' }


  ];

  setLoading(status: boolean) {
    this.loading = status;
  }

  MyGarageFavorites = {
    EnableFavorites: true,
    FavoritesMaxLimit: 30
  }

  oldSaveVehicle() {
    console.log('old save vehicle ...');

    this.vehicles[0].vehicleSaved = true;

    console.log(this.vehicles);
  }

  // modal ------------

  openModal() {
    this.modalService.open(IframeComponent, {
      src: "https://www6.qaautonation.com/appointment?hid=2340&vin=JTHGZ1B20P5063037&type=1&iframe=true&srcpath=vdp",
      title: 'Appointment and Reservation',
      id: 'myIframeId'
    }, {
      maxWidth: '994px'
    }).onClosed.subscribe(_ => console.log('Appointment modal closed ...'));
  }

  openModalHours() {
    const salesDepartment = {
      "Name": "Sales",
      "Hours": null,
      "ContactName": null,
      "ContactPhone": null,
      "ContactEmail": null,
      "DepartmentType": "Sales",
      "DetailedHours": [
        {
          "Day": 0,
          "StartTime": "9:00 AM",
          "EndTime": "9:00 PM",
          "Reoccurence": 0
        },
        {
          "Day": 1,
          "StartTime": "9:00 AM",
          "EndTime": "9:00 PM",
          "Reoccurence": 0
        },
        {
          "Day": 2,
          "StartTime": "9:00 AM",
          "EndTime": "9:00 PM",
          "Reoccurence": 0
        },
        {
          "Day": 3,
          "StartTime": "9:00 AM",
          "EndTime": "9:00 PM",
          "Reoccurence": 0
        },
        {
          "Day": 4,
          "StartTime": "9:00 AM",
          "EndTime": "9:00 PM",
          "Reoccurence": 0
        },
        {
          "Day": 5,
          "StartTime": "9:00 AM",
          "EndTime": "9:00 PM",
          "Reoccurence": 0
        },
        {
          "Day": 6,
          "StartTime": "11:00 AM",
          "EndTime": "7:00 PM",
          "Reoccurence": 0
        }
      ],
      "Brands": null
    };

    this.modalService.open(HoursComponent, {
      department: salesDepartment
    }, {
      maxWidth: '430px'
    }).onClosed.subscribe(v => {
      console.log('closing hours modal');
    })
  }

  openSRPCompareVehicleModal() {
    let url = 'aks-sitecore.internal.qaautonation.com/common-service/comparevehicles/?vin=W1KCG2DB8NA017510|4JGGM2BB1PA008315|4JGGM2BB4PA007157&zipcode=33301&fuelTypeDetailsKey=EF&ShowCompareonVDP=false';

    const reqObj = {
      carsArray: mock.Data,
      viewMode: 'modal'
    }

    this.compareVehicleModalService.triggerCompareVehicleModal(reqObj).subscribe((doAuthOnClosing: any) => {
      console.log('Return Data - ', doAuthOnClosing);
    });
  }

  loadVDPCompareVehicleSection() {
    let url = 'aks-sitecore.internal.qaautonation.com/common-service/comparevehicles/?vin=W1KCG2DB8NA017510&zipcode=33301&fuelTypeDetailsKey=EF&ShowCompareonVDP=true';

    const reqObj = {
      carsArray: mock.Data,
      viewMode: 'non-modal'
    }

    document.dispatchEvent(new CustomEvent("__LOAD__VEHICLE__COMPARE__SECTION__", {
      detail: reqObj,
    })
    );
  }

  openAddVehicleModal() {

    const mockData = {
      isFinishAddingFlow: false,
      vehciles: [{
        VehicleId: '11b9c819-58b1-479e-a8ba-088912bc3a74',
        Make: 'Ford',
        Model: 'Escape',
        Year: '2022',
        models: [
          {
            "id": 35192,
            "name": "Bronco"
          },
          {
            "id": 35070,
            "name": "Bronco Sport"
          },
          {
            "id": 34022,
            "name": "E-Series Cutaway"
          },
          {
            "id": 34023,
            "name": "E-Series Stripped Chassis"
          },
          {
            "id": 34441,
            "name": "E-Transit Cargo Van"
          },
          {
            "id": 34752,
            "name": "E-Transit Chassis"
          },
          {
            "id": 34753,
            "name": "E-Transit Cutaway"
          },
          {
            "id": 34789,
            "name": "EcoSport"
          },
          {
            "id": 34851,
            "name": "Edge"
          },
          {
            "id": 34526,
            "name": "Escape"
          },
          {
            "id": 34795,
            "name": "Expedition"
          },
          {
            "id": 34894,
            "name": "Expedition Max"
          },
          {
            "id": 34788,
            "name": "Explorer"
          },
          {
            "id": 35085,
            "name": "F-150"
          },
          {
            "id": 35373,
            "name": "F-150 Lightning"
          },
          {
            "id": 35129,
            "name": "F-150 Police Responder"
          },
          {
            "id": 35156,
            "name": "F-150 Special Service Vehicle"
          },
          {
            "id": 35116,
            "name": "F-53 Motorhome Stripped Chassis"
          },
          {
            "id": 35117,
            "name": "F-59 Commercial Stripped Chassis"
          },
          {
            "id": 34103,
            "name": "F-650 Pro Loader"
          },
          {
            "id": 34104,
            "name": "F-650 Pro Loader Gas"
          },
          {
            "id": 34105,
            "name": "F-650 Straight Frame"
          },
          {
            "id": 34106,
            "name": "F-650 Straight Frame Gas"
          },
          {
            "id": 34107,
            "name": "F-650 Tractor"
          },
          {
            "id": 34108,
            "name": "F-750 Straight Frame"
          },
          {
            "id": 34109,
            "name": "F-750 Straight Frame Gas"
          },
          {
            "id": 34110,
            "name": "F-750 Tractor"
          },
          {
            "id": 34705,
            "name": "Maverick"
          },
          {
            "id": 35213,
            "name": "Mustang"
          },
          {
            "id": 35133,
            "name": "Mustang Mach-E"
          },
          {
            "id": 34903,
            "name": "Police Interceptor Utility"
          },
          {
            "id": 35138,
            "name": "Ranger"
          },
          {
            "id": 34313,
            "name": "Super Duty F-250 SRW"
          },
          {
            "id": 34304,
            "name": "Super Duty F-350 DRW"
          },
          {
            "id": 34314,
            "name": "Super Duty F-350 DRW"
          },
          {
            "id": 34305,
            "name": "Super Duty F-350 SRW"
          },
          {
            "id": 34315,
            "name": "Super Duty F-350 SRW"
          },
          {
            "id": 34303,
            "name": "Super Duty F-450 DRW"
          },
          {
            "id": 34326,
            "name": "Super Duty F-450 DRW"
          },
          {
            "id": 34311,
            "name": "Super Duty F-550 DRW"
          },
          {
            "id": 34309,
            "name": "Super Duty F-600 DRW"
          },
          {
            "id": 34439,
            "name": "Transit Cargo Van"
          },
          {
            "id": 34751,
            "name": "Transit Chassis"
          },
          {
            "id": 34230,
            "name": "Transit Connect Van"
          },
          {
            "id": 34231,
            "name": "Transit Connect Wagon"
          },
          {
            "id": 34440,
            "name": "Transit Crew Van"
          },
          {
            "id": 34750,
            "name": "Transit Cutaway"
          },
          {
            "id": 34469,
            "name": "Transit Passenger Wagon"
          }
        ],
        "Trim": [],
        ImageUrl: 'https://www6.qaautonation.com/voi/image/2021/FIAT/500X',
      },
        //   {
        //     VehicleId: '3267f98f-5724-4495-97d7-4c6b82f5f1cc',
        //     Make: 'Ford',
        //     Model: 'Escape',
        //     Year: '2022',
        //     models: [
        //       {
        //           "id": 35192,
        //           "name": "Bronco"
        //       },
        //       {
        //           "id": 35070,
        //           "name": "Bronco Sport"
        //       },
        //       {
        //           "id": 34022,
        //           "name": "E-Series Cutaway"
        //       },
        //       {
        //           "id": 34023,
        //           "name": "E-Series Stripped Chassis"
        //       },
        //       {
        //           "id": 34441,
        //           "name": "E-Transit Cargo Van"
        //       },
        //       {
        //           "id": 34752,
        //           "name": "E-Transit Chassis"
        //       },
        //       {
        //           "id": 34753,
        //           "name": "E-Transit Cutaway"
        //       },
        //       {
        //           "id": 34789,
        //           "name": "EcoSport"
        //       },
        //       {
        //           "id": 34851,
        //           "name": "Edge"
        //       },
        //       {
        //           "id": 34526,
        //           "name": "Escape"
        //       },
        //       {
        //           "id": 34795,
        //           "name": "Expedition"
        //       },
        //       {
        //           "id": 34894,
        //           "name": "Expedition Max"
        //       },
        //       {
        //           "id": 34788,
        //           "name": "Explorer"
        //       },
        //       {
        //           "id": 35085,
        //           "name": "F-150"
        //       },
        //       {
        //           "id": 35373,
        //           "name": "F-150 Lightning"
        //       },
        //       {
        //           "id": 35129,
        //           "name": "F-150 Police Responder"
        //       },
        //       {
        //           "id": 35156,
        //           "name": "F-150 Special Service Vehicle"
        //       },
        //       {
        //           "id": 35116,
        //           "name": "F-53 Motorhome Stripped Chassis"
        //       },
        //       {
        //           "id": 35117,
        //           "name": "F-59 Commercial Stripped Chassis"
        //       },
        //       {
        //           "id": 34103,
        //           "name": "F-650 Pro Loader"
        //       },
        //       {
        //           "id": 34104,
        //           "name": "F-650 Pro Loader Gas"
        //       },
        //       {
        //           "id": 34105,
        //           "name": "F-650 Straight Frame"
        //       },
        //       {
        //           "id": 34106,
        //           "name": "F-650 Straight Frame Gas"
        //       },
        //       {
        //           "id": 34107,
        //           "name": "F-650 Tractor"
        //       },
        //       {
        //           "id": 34108,
        //           "name": "F-750 Straight Frame"
        //       },
        //       {
        //           "id": 34109,
        //           "name": "F-750 Straight Frame Gas"
        //       },
        //       {
        //           "id": 34110,
        //           "name": "F-750 Tractor"
        //       },
        //       {
        //           "id": 34705,
        //           "name": "Maverick"
        //       },
        //       {
        //           "id": 35213,
        //           "name": "Mustang"
        //       },
        //       {
        //           "id": 35133,
        //           "name": "Mustang Mach-E"
        //       },
        //       {
        //           "id": 34903,
        //           "name": "Police Interceptor Utility"
        //       },
        //       {
        //           "id": 35138,
        //           "name": "Ranger"
        //       },
        //       {
        //           "id": 34313,
        //           "name": "Super Duty F-250 SRW"
        //       },
        //       {
        //           "id": 34304,
        //           "name": "Super Duty F-350 DRW"
        //       },
        //       {
        //           "id": 34314,
        //           "name": "Super Duty F-350 DRW"
        //       },
        //       {
        //           "id": 34305,
        //           "name": "Super Duty F-350 SRW"
        //       },
        //       {
        //           "id": 34315,
        //           "name": "Super Duty F-350 SRW"
        //       },
        //       {
        //           "id": 34303,
        //           "name": "Super Duty F-450 DRW"
        //       },
        //       {
        //           "id": 34326,
        //           "name": "Super Duty F-450 DRW"
        //       },
        //       {
        //           "id": 34311,
        //           "name": "Super Duty F-550 DRW"
        //       },
        //       {
        //           "id": 34309,
        //           "name": "Super Duty F-600 DRW"
        //       },
        //       {
        //           "id": 34439,
        //           "name": "Transit Cargo Van"
        //       },
        //       {
        //           "id": 34751,
        //           "name": "Transit Chassis"
        //       },
        //       {
        //           "id": 34230,
        //           "name": "Transit Connect Van"
        //       },
        //       {
        //           "id": 34231,
        //           "name": "Transit Connect Wagon"
        //       },
        //       {
        //           "id": 34440,
        //           "name": "Transit Crew Van"
        //       },
        //       {
        //           "id": 34750,
        //           "name": "Transit Cutaway"
        //       },
        //       {
        //           "id": 34469,
        //           "name": "Transit Passenger Wagon"
        //       }
        //     ],
        //     "Trim": [],
        //     ImageUrl: 'https://www6.qaautonation.com/voi/image/2017/Audi/A5%20Cabriolet',
        //   }
        //   {
        //     VehicleId: '31',
        //     Make: 'Ford',
        //     Model: 'Escape 31',
        //     Year: '2022',
        //     models: [
        //       {
        //           "id": 35192,
        //           "name": "Bronco"
        //       },
        //       {
        //           "id": 35070,
        //           "name": "Bronco Sport"
        //       },
        //       {
        //           "id": 34022,
        //           "name": "E-Series Cutaway"
        //       },
        //       {
        //           "id": 34023,
        //           "name": "E-Series Stripped Chassis"
        //       },
        //       {
        //           "id": 34441,
        //           "name": "E-Transit Cargo Van"
        //       },
        //       {
        //           "id": 34752,
        //           "name": "E-Transit Chassis"
        //       },
        //       {
        //           "id": 34753,
        //           "name": "E-Transit Cutaway"
        //       },
        //       {
        //           "id": 34789,
        //           "name": "EcoSport"
        //       },
        //       {
        //           "id": 34851,
        //           "name": "Edge"
        //       },
        //       {
        //           "id": 34526,
        //           "name": "Escape"
        //       },
        //       {
        //           "id": 34795,
        //           "name": "Expedition"
        //       },
        //       {
        //           "id": 34894,
        //           "name": "Expedition Max"
        //       },
        //       {
        //           "id": 34788,
        //           "name": "Explorer"
        //       },
        //       {
        //           "id": 35085,
        //           "name": "F-150"
        //       },
        //       {
        //           "id": 35373,
        //           "name": "F-150 Lightning"
        //       },
        //       {
        //           "id": 35129,
        //           "name": "F-150 Police Responder"
        //       },
        //       {
        //           "id": 35156,
        //           "name": "F-150 Special Service Vehicle"
        //       },
        //       {
        //           "id": 35116,
        //           "name": "F-53 Motorhome Stripped Chassis"
        //       },
        //       {
        //           "id": 35117,
        //           "name": "F-59 Commercial Stripped Chassis"
        //       },
        //       {
        //           "id": 34103,
        //           "name": "F-650 Pro Loader"
        //       },
        //       {
        //           "id": 34104,
        //           "name": "F-650 Pro Loader Gas"
        //       },
        //       {
        //           "id": 34105,
        //           "name": "F-650 Straight Frame"
        //       },
        //       {
        //           "id": 34106,
        //           "name": "F-650 Straight Frame Gas"
        //       },
        //       {
        //           "id": 34107,
        //           "name": "F-650 Tractor"
        //       },
        //       {
        //           "id": 34108,
        //           "name": "F-750 Straight Frame"
        //       },
        //       {
        //           "id": 34109,
        //           "name": "F-750 Straight Frame Gas"
        //       },
        //       {
        //           "id": 34110,
        //           "name": "F-750 Tractor"
        //       },
        //       {
        //           "id": 34705,
        //           "name": "Maverick"
        //       },
        //       {
        //           "id": 35213,
        //           "name": "Mustang"
        //       },
        //       {
        //           "id": 35133,
        //           "name": "Mustang Mach-E"
        //       },
        //       {
        //           "id": 34903,
        //           "name": "Police Interceptor Utility"
        //       },
        //       {
        //           "id": 35138,
        //           "name": "Ranger"
        //       },
        //       {
        //           "id": 34313,
        //           "name": "Super Duty F-250 SRW"
        //       },
        //       {
        //           "id": 34304,
        //           "name": "Super Duty F-350 DRW"
        //       },
        //       {
        //           "id": 34314,
        //           "name": "Super Duty F-350 DRW"
        //       },
        //       {
        //           "id": 34305,
        //           "name": "Super Duty F-350 SRW"
        //       },
        //       {
        //           "id": 34315,
        //           "name": "Super Duty F-350 SRW"
        //       },
        //       {
        //           "id": 34303,
        //           "name": "Super Duty F-450 DRW"
        //       },
        //       {
        //           "id": 34326,
        //           "name": "Super Duty F-450 DRW"
        //       },
        //       {
        //           "id": 34311,
        //           "name": "Super Duty F-550 DRW"
        //       },
        //       {
        //           "id": 34309,
        //           "name": "Super Duty F-600 DRW"
        //       },
        //       {
        //           "id": 34439,
        //           "name": "Transit Cargo Van"
        //       },
        //       {
        //           "id": 34751,
        //           "name": "Transit Chassis"
        //       },
        //       {
        //           "id": 34230,
        //           "name": "Transit Connect Van"
        //       },
        //       {
        //           "id": 34231,
        //           "name": "Transit Connect Wagon"
        //       },
        //       {
        //           "id": 34440,
        //           "name": "Transit Crew Van"
        //       },
        //       {
        //           "id": 34750,
        //           "name": "Transit Cutaway"
        //       },
        //       {
        //           "id": 34469,
        //           "name": "Transit Passenger Wagon"
        //       }
        //     ],
        //     "Trim": [],
        //     ImageUrl: 'https://www6.qaautonation.com/voi/image/2021/Dodge/Charger',
        //   },
        //   {
        //     VehicleId: 'q32',
        //     Make: 'Ford',
        //     Model: 'Escape 32',
        //     Year: '2022',
        //     "Trim": [
        //       {
        //           "id": 442087,
        //           "name": "Premium 50 quattro"
        //       },
        //       {
        //           "id": 442088,
        //           "name": "Premium Plus 50 quattro"
        //       },
        //       {
        //           "id": 442089,
        //           "name": "Prestige 50 quattro"
        //       }
        //   ],
        //     ImageUrl: 'https://www6.qaautonation.com/voi/image/2021/FIAT/500X',
        //   },
        //   {
        //     VehicleId: '32',
        //     Make: 'Ford',
        //     Model: 'Escape',
        //     Year: '2022',
        //     "Trim": [
        //       {
        //           "id": 442087,
        //           "name": "Premium 50 quattro"
        //       },
        //       {
        //           "id": 442088,
        //           "name": "Premium Plus 50 quattro"
        //       },
        //       {
        //           "id": 442089,
        //           "name": "Prestige 50 quattro"
        //       }
        //   ],
        //     ImageUrl: 'http://media.chromedata.com/MediaGallery/media/MjcyNzMxXk1lZGlhIEdhbGxlcnk/E-gyFz8DbRGBMnVLdI_UUAKCrd4n157GtGaqkiZUYFxdL2eUTiiYzc2Ay-HLEKrE3eGaWIyOV49QaJt8c0FnD4AxRreTs15EECJMfHtzfjpHJpupjuP_NRwO4-w8_FInZO_ip4IBmHPt7R0QxE0uXGa55UXU6cl3TQDLGktGV6E/cc_2019HOC010076_01_320_GY.png',
        //   },
        //   {
        //      Make: 'Ford1',
        //      VehicleId: '2',
        //      Model: 'Escape1',
        //      Year: '2021',
        //      "Trim": [
        //       {
        //           "id": 442087,
        //           "name": "Premium 50 quattro"
        //       },
        //       {
        //           "id": 442088,
        //           "name": "Premium Plus 50 quattro"
        //       },
        //       {
        //           "id": 442089,
        //           "name": "Prestige 50 quattro"
        //       }
        //   ],
        //      ImageUrl: 'https://www6.qaautonation.com/voi/image/2021/FIAT/500X',
        //    }
      ],
      addThisVehicle: 'Add This Vehicle',
      remove: 'Remove This Vehicle',
      ask: 'Ask Me Later',
      done: 'Done',
      header: 'Add a Vehicle to Your Account',
      subHeader: 'Good news! These vehicles are connected to your email address. By adding any of them to your account, you can easily stay on top of maintenance, appointments, trade-in offers, and exciting features.',

    };

    this.addVehicleModalService.triggerAddVehicleModal(mockData).subscribe((doAuthOnClosing: any) => {
      console.log('Return Data - ', doAuthOnClosing);

    });

  }

  openFinishAddVehicleModal() {

    const mockData = {
      isFinishAddingFlow: true,
      finishVehicle: {
        selectedSeatColor: 'Silver',
        selectedSeatMaterial: 'Cloth',
        givenNickName: 'adasd',
        selectedExteriorColor: 'Grigio Ferro Metallic',
        VehicleId: 'aec56c31-91f9-4851-9886-a535baf42dab',
        Make: 'Ford',
        Model: 'Escape',
        Year: '2022',
        models: [
          {
            "id": 35192,
            "name": "Bronco"
          },
          {
            "id": 35070,
            "name": "Bronco Sport"
          },
          {
            "id": 34022,
            "name": "E-Series Cutaway"
          },
          {
            "id": 34023,
            "name": "E-Series Stripped Chassis"
          },
          {
            "id": 34441,
            "name": "E-Transit Cargo Van"
          },
          {
            "id": 34752,
            "name": "E-Transit Chassis"
          },
          {
            "id": 34753,
            "name": "E-Transit Cutaway"
          },
          {
            "id": 34789,
            "name": "EcoSport"
          },
          {
            "id": 34851,
            "name": "Edge"
          },
          {
            "id": 34526,
            "name": "Escape"
          },
          {
            "id": 34795,
            "name": "Expedition"
          },
          {
            "id": 34894,
            "name": "Expedition Max"
          },
          {
            "id": 34788,
            "name": "Explorer"
          },
          {
            "id": 35085,
            "name": "F-150"
          },
          {
            "id": 35373,
            "name": "F-150 Lightning"
          },
          {
            "id": 35129,
            "name": "F-150 Police Responder"
          },
          {
            "id": 35156,
            "name": "F-150 Special Service Vehicle"
          },
          {
            "id": 35116,
            "name": "F-53 Motorhome Stripped Chassis"
          },
          {
            "id": 35117,
            "name": "F-59 Commercial Stripped Chassis"
          },
          {
            "id": 34103,
            "name": "F-650 Pro Loader"
          },
          {
            "id": 34104,
            "name": "F-650 Pro Loader Gas"
          },
          {
            "id": 34105,
            "name": "F-650 Straight Frame"
          },
          {
            "id": 34106,
            "name": "F-650 Straight Frame Gas"
          },
          {
            "id": 34107,
            "name": "F-650 Tractor"
          },
          {
            "id": 34108,
            "name": "F-750 Straight Frame"
          },
          {
            "id": 34109,
            "name": "F-750 Straight Frame Gas"
          },
          {
            "id": 34110,
            "name": "F-750 Tractor"
          },
          {
            "id": 34705,
            "name": "Maverick"
          },
          {
            "id": 35213,
            "name": "Mustang"
          },
          {
            "id": 35133,
            "name": "Mustang Mach-E"
          },
          {
            "id": 34903,
            "name": "Police Interceptor Utility"
          },
          {
            "id": 35138,
            "name": "Ranger"
          },
          {
            "id": 34313,
            "name": "Super Duty F-250 SRW"
          },
          {
            "id": 34304,
            "name": "Super Duty F-350 DRW"
          },
          {
            "id": 34314,
            "name": "Super Duty F-350 DRW"
          },
          {
            "id": 34305,
            "name": "Super Duty F-350 SRW"
          },
          {
            "id": 34315,
            "name": "Super Duty F-350 SRW"
          },
          {
            "id": 34303,
            "name": "Super Duty F-450 DRW"
          },
          {
            "id": 34326,
            "name": "Super Duty F-450 DRW"
          },
          {
            "id": 34311,
            "name": "Super Duty F-550 DRW"
          },
          {
            "id": 34309,
            "name": "Super Duty F-600 DRW"
          },
          {
            "id": 34439,
            "name": "Transit Cargo Van"
          },
          {
            "id": 34751,
            "name": "Transit Chassis"
          },
          {
            "id": 34230,
            "name": "Transit Connect Van"
          },
          {
            "id": 34231,
            "name": "Transit Connect Wagon"
          },
          {
            "id": 34440,
            "name": "Transit Crew Van"
          },
          {
            "id": 34750,
            "name": "Transit Cutaway"
          },
          {
            "id": 34469,
            "name": "Transit Passenger Wagon"
          }
        ],
        "Trim": [],
        ImageUrl: 'https://www6.qaautonation.com/voi/image/2024/Audi/A4%20Sedan',
        "exteriorColors": [
          "Bright White Clearcoat",
          "Ceramic Grey Clearcoat",
          "Gloss Black",
          "Granite Crystal Metallic Clearcoat",
          "Maximum Steel Metallic Clearcoat",
          "Ocean Blue Metallic Clearcoat",
          "Silver Mist Clearcoat",
          "Velvet Red Pearlcoat"
        ],
        "interiorColors": [
          "Beige",
          "Gray",
          "Black",
          "Tan",
          "Blue",
          "Brown",
          "Gold",
          "Green",
          "Maroon",
          "Orange",
          "Platinum",
          "Purple",
          "Red",
          "Silver",
          "Teal",
          "White",
          "Yellow"
        ],
        SeatMaterial: [
          {
            value: "Leather", description: "Leather"
          },
          {
            value: "Cloth", description: "Cloth"
          },
          {
            value: "Vinyl", description: "Vinyl"
          }
        ],
        estimatedMileage: 121323
      },
      addThisVehicle: 'Add This Vehicle',
      remove: 'Remove This Vehicle',
      ask: 'Ask Me Later',
      done: 'Done',
      header: 'Finish Adding Your Vehicle',
      subHeader: 'Adding a vehicle to your account allows you to keep up with your vehicle’s maintenance, create appointments, get a certified trade-in offer, and much more.',
      finishAddingLabelData: {
        requiredField: '*Indicates required field',
        VehicleNickname: 'Vehicle Nickname',
        AddNickName: 'Add a Nickname',
        ExteriorColor: 'Exterior Color',
        SeatColor: 'Seat Color',
        SeatMaterial: 'Seat Material',
        EstimatedMileage: 'Esitmated Mileage',
        AddMileage: 'Add Mileage',
      }
    };

    this.addVehicleModalService.triggerAddVehicleModal(mockData).subscribe((doAuthOnClosing: any) => {
      console.log('Return Data - ', doAuthOnClosing);

    });

  }

  openModalConfirm() {
    const modal = this.modalService.openConfirm({
      header: "Confirm Cancel Appointment",
      subText: "Are you sure you want to cancel your appointment?",
      buttonText: "No, Go Back",
      buttonText2: "Yes, Cancel my Appointment"
    });

    modal.onClickButton2.subscribe((_: any) => {
      console.log('do something...');
    });
  }


  openAuthModalAppointment(source?: string) {
    this.authModalService.triggerAuthModal(source).subscribe((doAuthOnClosing: any) => {
      if (doAuthOnClosing) {
        console.log('Navigate to Authentication Page with', doAuthOnClosing, 'mode');
        try {
          if ((window as any).auth) {
            (window as any).auth.loginWithAuth0(doAuthOnClosing, 'mg=f');
          }
        } catch (e) {/* istanbul ignore next */
          console.log(e);
        }
      }
    });
  }

  openAuthModalSRPSearch(source?: string) {
    this.authModalService.triggerAuthModal(source).subscribe((doAuthOnClosing: any) => {
      if (doAuthOnClosing) {
        console.log('Navigate to Authentication Page with', doAuthOnClosing, 'mode');
        try {
          if ((window as any).auth) {
            (window as any).auth.loginWithAuth0(doAuthOnClosing, 'mg=f');
          }
        } catch (e) {/* istanbul ignore next */
          console.log(e);
        }
      }
    });
  }

  openNotificationModal(isApp?: boolean) {

    document.dispatchEvent(
      new CustomEvent("__OPEN__NOTIFICATION__MODAL__", {
        detail: 'hello',
      })
    );
  }

  openVerifyEmailModal() {
    this.verifyEmailModalService.triggerVerifyEmailModal().subscribe((doAuthOnClosing: any) => {
      console.log('Return Data - ', doAuthOnClosing);
    });
  }

  openSRPVerifyEmailModal() {
    this.verifyEmailModalService.triggerVerifyEmailModal('srp-search').subscribe((doAuthOnClosing: any) => {
      console.log('Return Data - ', doAuthOnClosing);
    });
  }

}
